/*
Theme Name: gocollegenow
Theme URI: http://wordpress.org/themes/gocollegenow
Author: iiidesign
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  font: inherit;
  padding: 0;
  border: 0;
  margin: 0;
  vertical-align: baseline;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
.clear {
  clear: both;
}
body {
  background: #460b0c;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  color: #434343;
  font-size: 16px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
}
a {
  color: #434343;
}
i {
  font-style: italic;
}
.clear {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.inline-block {
  display: inline-block;
}
.border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.box {
  float: left;
  clear: both;
  width: 100%;
}
.box:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.container {
  float: left;
  clear: both;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
@media screen and (min-width: 798px) {
  .container {
    display: block;
    margin: auto;
    max-width: 980px;
    float: none;
  }
}
.super-container {
  -webkit-font-smoothing: antialiased;
  float: left;
  clear: both;
  width: 100%;
  background-image: url('img/bg/paper-light.jpg');
  overflow: hidden;
}
.super-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.sketch-line-blue-top {
  padding-top: 40px;
  position: relative;
}
.sketch-line-blue-top:before {
  content: " ";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 20px;
  background-image: url('img/sketch-line-blue.png');
  background-size: auto 19px;
  background-position-y: bottom;
  background-position-x: right;
}
@media screen and (min-width: 798px) {
  .sketch-line-blue-top:before {
    left: -60px;
    right: -60px;
    background-size: cover;
    background-position-x: left;
    background-repeat-x: no-repeat;
  }
}
.stats-callout {
  float: left;
  clear: both;
  width: 100%;
  display: none;
  position: relative;
  padding: 30px 0;
  z-index: 0;
  margin-bottom: 40px;
  color: #ebfffe;
}
.stats-callout:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.stats-callout:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10000px;
  right: -10000px;
  z-index: -1;
  background-image: url('img/bg/paper-teal.jpg');
}
.stats-callout .fact-callout {
  font-family: 'Rock Salt', cursive;
  font-size: 28px;
  line-height: 35px;
  text-transform: lowercase;
  letter-spacing: 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 30px 20px 30px 0;
  float: left;
  width: 170px;
  position: relative;
  z-index: 0;
  word-break: auto;
  hypens: auto;
}
.stats-callout .fact-callout:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -10000px;
  z-index: -1;
  background-color: #00454f;
  opacity: .5;
}
.stats-callout .fact-callout:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: -13px;
  width: 13px;
  background-image: url('img/bg/triangle-after-bg.png');
  background-size: 100% 100%;
}
.stats-callout .fact-content {
  float: left;
  width: 56%;
  margin-left: 5%;
  font-weight: 100;
  line-height: 35px;
  font-family: 'Source Sans Pro', Arial, sans-serif;
}
.stats-callout .fact-content p {
  font-size: 25px;
  margin: 0;
}
.stats-callout .fact-content strong {
  font-family: 'Graduate';
  font-size: 45px;
  letter-spacing: 2px;
}
.stats-callout .fact-content h6 {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  color: #ebfffe;
  font-size: 13px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 0;
}
.stats-callout .fact-content h6 a {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  color: #ebfffe;
}
.stats-callout .fact-content img {
  display: block;
  max-width: 100%;
  margin-bottom: 15px;
  height: auto;
}
.stats-callout .fact-content img.aligncenter {
  margin: auto;
  margin-bottom: 30px;
  height: auto;
}
.stats-callout .fact-content br {
  display: block;
}
.stats-callout .view-save-share {
  font-family: 'Podkova', serif;
  color: #faf0e6;
  letter-spacing: .5px;
  text-decoration: none;
  padding: 10px;
  float: left;
  margin: 5px 5px 0 0;
  position: relative;
  z-index: 0;
  color: #006675;
  line-height: 18px;
  width: 140px;
  position: absolute;
  right: -10px;
  top: 70px;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.stats-callout .view-save-share:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.stats-callout .view-save-share:last-child {
  margin: 5px 0 0 0;
}
.stats-callout .view-save-share:hover {
  color: #faf0e6;
  text-decoration: none;
}
.stats-callout .view-save-share:hover:before {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
.stats-callout .view-save-share:before {
  background-color: #97bdc0;
}
.stats-callout .view-save-share i {
  font-size: 35px;
  float: left;
  margin-right: 10px;
}
.stats-callout .view-save-share:hover {
  color: #006675;
  text-decoration: none;
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.stats-callout .view-all {
  font-family: 'Podkova', serif;
  color: #faf0e6;
  letter-spacing: .5px;
  text-decoration: none;
  padding: 10px;
  float: left;
  margin: 5px 5px 0 0;
  position: relative;
  z-index: 0;
  color: inherit;
  text-transform: lowercase;
  padding: 0 20px;
  letter-spacing: 1px;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 135px;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.stats-callout .view-all:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.stats-callout .view-all:last-child {
  margin: 5px 0 0 0;
}
.stats-callout .view-all:hover {
  color: #faf0e6;
  text-decoration: none;
}
.stats-callout .view-all:hover:before {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
.stats-callout .view-all:before {
  background-color: #00444f;
  opacity: .5;
}
.stats-callout .view-all:hover {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  text-decoration: none;
}
.stats-callout br {
  display: block;
}
@media screen and (min-width: 798px) {
  .stats-callout {
    float: left;
    clear: both;
    width: 100%;
    display: none;
    position: relative;
    padding: 30px 0;
    z-index: 0;
    margin-bottom: 40px;
    color: #ebfffe;
    display: block;
  }
  .stats-callout:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .stats-callout:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10000px;
    right: -10000px;
    z-index: -1;
    background-image: url('img/bg/paper-teal.jpg');
  }
  .stats-callout .fact-callout {
    font-family: 'Rock Salt', cursive;
    font-size: 28px;
    line-height: 35px;
    text-transform: lowercase;
    letter-spacing: 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 30px 20px 30px 0;
    float: left;
    width: 170px;
    position: relative;
    z-index: 0;
    word-break: auto;
    hypens: auto;
  }
  .stats-callout .fact-callout:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: -10000px;
    z-index: -1;
    background-color: #00454f;
    opacity: .5;
  }
  .stats-callout .fact-callout:after {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: -13px;
    width: 13px;
    background-image: url('img/bg/triangle-after-bg.png');
    background-size: 100% 100%;
  }
  .stats-callout .fact-content {
    float: left;
    width: 56%;
    margin-left: 5%;
    font-weight: 100;
    line-height: 35px;
    font-family: 'Source Sans Pro', Arial, sans-serif;
  }
  .stats-callout .fact-content p {
    font-size: 25px;
    margin: 0;
  }
  .stats-callout .fact-content strong {
    font-family: 'Graduate';
    font-size: 45px;
    letter-spacing: 2px;
  }
  .stats-callout .fact-content h6 {
    font-family: 'Source Sans Pro', Arial, sans-serif;
    color: #ebfffe;
    font-size: 13px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 0;
  }
  .stats-callout .fact-content h6 a {
    font-family: 'Source Sans Pro', Arial, sans-serif;
    color: #ebfffe;
  }
  .stats-callout .fact-content img {
    display: block;
    max-width: 100%;
    margin-bottom: 15px;
    height: auto;
  }
  .stats-callout .fact-content img.aligncenter {
    margin: auto;
    margin-bottom: 30px;
    height: auto;
  }
  .stats-callout .fact-content br {
    display: block;
  }
  .stats-callout .view-save-share {
    font-family: 'Podkova', serif;
    color: #faf0e6;
    letter-spacing: .5px;
    text-decoration: none;
    padding: 10px;
    float: left;
    margin: 5px 5px 0 0;
    position: relative;
    z-index: 0;
    color: #006675;
    line-height: 18px;
    width: 140px;
    position: absolute;
    right: -10px;
    top: 70px;
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  .stats-callout .view-save-share:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #8f0005;
    transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
  .stats-callout .view-save-share:last-child {
    margin: 5px 0 0 0;
  }
  .stats-callout .view-save-share:hover {
    color: #faf0e6;
    text-decoration: none;
  }
  .stats-callout .view-save-share:hover:before {
    filter: brightness(125%);
    -webkit-filter: brightness(125%);
    -moz-filter: brightness(125%);
    -o-filter: brightness(125%);
    -ms-filter: brightness(125%);
  }
  .stats-callout .view-save-share:before {
    background-color: #97bdc0;
  }
  .stats-callout .view-save-share i {
    font-size: 35px;
    float: left;
    margin-right: 10px;
  }
  .stats-callout .view-save-share:hover {
    color: #006675;
    text-decoration: none;
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
  }
  .stats-callout .view-all {
    font-family: 'Podkova', serif;
    color: #faf0e6;
    letter-spacing: .5px;
    text-decoration: none;
    padding: 10px;
    float: left;
    margin: 5px 5px 0 0;
    position: relative;
    z-index: 0;
    color: inherit;
    text-transform: lowercase;
    padding: 0 20px;
    letter-spacing: 1px;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 135px;
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  .stats-callout .view-all:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #8f0005;
    transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
  .stats-callout .view-all:last-child {
    margin: 5px 0 0 0;
  }
  .stats-callout .view-all:hover {
    color: #faf0e6;
    text-decoration: none;
  }
  .stats-callout .view-all:hover:before {
    filter: brightness(125%);
    -webkit-filter: brightness(125%);
    -moz-filter: brightness(125%);
    -o-filter: brightness(125%);
    -ms-filter: brightness(125%);
  }
  .stats-callout .view-all:before {
    background-color: #00444f;
    opacity: .5;
  }
  .stats-callout .view-all:hover {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
    text-decoration: none;
  }
  .stats-callout br {
    display: block;
  }
  .lang-es .stats-callout .view-all:last-child,
  .single-pledge .stats-callout .view-all:last-child {
    max-width: 115px;
    line-height: 1.2em;
    padding: 12px 20px;
  }
  .lang-es .fact-callout,
  .single-pledge .fact-callout {
    font-size: 25px;
  }
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 5px;
  height: 0;
}
.video-container embed,
.video-container iframe,
.video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}
.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}
.fancybox-lock .fancybox-overlay {
  background-color: rgba(37, 37, 37, 0.7);
  z-index: 100000;
}
.fancybox-close {
  top: 4px !important;
  right: -4px !important;
  width: 36px;
  height: 36px;
  color: #faf0e6;
  background-image: none !important;
  text-decoration: none;
}
.fancybox-close:after {
  content: "\f00d";
  font-family: 'fontawesome';
  font-size: 30px;
}
.fancybox-close:hover {
  color: #fff;
}
.fancybox-outer {
  background-color: #460b0c;
  text-align: center;
}
.fancybox-outer .sponsor-callout {
  display: block;
  width: 200px;
  text-align: center;
  margin: 0 auto;
  padding: 30px 0 24px 0;
}
.fancybox-outer .sponsor-callout a {
  color: #faf0e6;
  text-decoration: none;
}
.fancybox-outer .sponsor-callout p {
  font-family: 'Oswald', sans-serif;
  font-weight: 100;
  color: #faf0e6;
  float: left;
  text-align: right;
  display: inline-block;
  text-decoration: none;
}
.fancybox-outer .sponsor-callout img {
  display: inline-block;
}
.fancybox-outer .close-popup-container {
  display: block;
  width: 100%;
  height: 40px;
}
header {
  background-color: #460b0c;
  font-family: 'Oswald', sans-serif;
}
header .container {
  position: relative;
  height: 95px;
}
header .header-logo {
  width: 85%;
  max-width: 330px;
  margin-left: -35px;
  position: absolute;
  z-index: 1;
}
header .localization {
  opacity: .85;
  margin-bottom: 15px;
}
header .localization-link {
  color: #faf0e6;
  font-weight: 100;
  text-decoration: none;
  margin-right: 5px;
}
header .localization-link.active {
  text-decoration: underline;
}
header .localization-link:hover {
  text-decoration: underline;
}
header .download-cirriculum-callout-mobile {
  display: none;
  margin-top: 20px;
  margin-bottom: 25px;
  float: left;
  clear: both;
  text-decoration: none;
  font-weight: 100;
  color: #faf0e6;
  font-size: 16px;
  opacity: .85;
}
header .logo-container-fixed {
  display: none;
}
header .menu-main-menu-container .menu,
header .menu-footer-menu-container .menu,
header .menu-main-menu-espanol-container .menu,
header .menu-footer-menu-espanol-container .menu {
  display: inline-block;
}
header .menu-main-menu-container > ul > li,
header .menu-footer-menu-container > ul > li,
header .menu-main-menu-espanol-container > ul > li,
header .menu-footer-menu-espanol-container > ul > li {
  padding: 10px 0;
}
header .menu-main-menu-container > ul > li a,
header .menu-footer-menu-container > ul > li a,
header .menu-main-menu-espanol-container > ul > li a,
header .menu-footer-menu-espanol-container > ul > li a {
  color: #faf0e6;
  font-weight: 100;
  font-size: 18px;
  letter-spacing: .02em;
  text-decoration: none;
  position: relative;
}
header .menu-main-menu-container .menu-item-has-children > a:after,
header .menu-footer-menu-container .menu-item-has-children > a:after,
header .menu-main-menu-espanol-container .menu-item-has-children > a:after,
header .menu-footer-menu-espanol-container .menu-item-has-children > a:after {
  content: "\f0dd";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: absolute;
  right: -18px;
  top: 3px;
}
header .download-cirriculum-callout {
  display: none;
}
header .logo-container {
  float: left;
}
header a[data-close] {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.4);
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 5px;
  z-index: 5;
}
header a[data-open] {
  position: absolute;
  top: 15px;
  right: 5px;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  cursor: pointer;
}
header .sub-menu {
  display: none;
}
header .header-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #460b0c;
  padding: 15px;
  visibility: hidden;
  opacity: 0;
  transition: 200ms opacity ease, 200ms visibility ease;
  -moz-transition: 200ms opacity ease, 200ms visibility ease;
  -webkit-transition: 200ms opacity ease, 200ms visibility ease;
  -o-transition: 200ms opacity ease, 200ms visibility ease;
  -ms-transition: 200ms opacity ease, 200ms visibility ease;
}
header .header-menu.active {
  visibility: visible;
  opacity: 1;
  z-index: 3;
  transition: 200ms opacity ease, 200ms visibility ease;
  -moz-transition: 200ms opacity ease, 200ms visibility ease;
  -webkit-transition: 200ms opacity ease, 200ms visibility ease;
  -o-transition: 200ms opacity ease, 200ms visibility ease;
  -ms-transition: 200ms opacity ease, 200ms visibility ease;
}
header .menu-item-has-children.active > a:after {
  content: "\f0d8" !important;
}
header .menu-item-has-children.active .sub-menu {
  display: block !important;
  width: 100% !important;
  float: left;
  clear: both;
  margin: 10px 0;
  padding-left: 30px;
}
header .menu-item-has-children.active .sub-menu li {
  padding: 5px 0;
}
@media screen and (min-width: 870px) {
  header {
    padding-bottom: 10px;
  }
  header .container {
    height: 100px;
  }
  header a[data-close],
  header a[data-open] {
    display: none;
  }
  header .logo-container {
    float: none;
  }
  header .header-logo {
    left: -20px;
    top: 0;
    margin-left: 0;
  }
  header .header-menu {
    position: static;
    padding: 0;
    visibility: visible;
    opacity: 1;
  }
  header .localization {
    float: right;
    opacity: 1;
    margin: 0;
  }
  header .menu-footer-menu-container,
  header .menu-footer-menu-espanol-container,
  header .download-cirriculum-callout-mobile {
    display: none;
  }
  header .download-cirriculum-callout {
    display: inline;
    margin-left: 30px;
    padding: 12px 25px 16px;
    float: right;
    color: #faf0e6;
    font-weight: 100;
    background-color: #630709;
    text-decoration: none;
    -ms-transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    transform: skewX(10deg);
  }
  header .download-cirriculum-callout p {
    -ms-transform: skewX(-10deg) !important;
    -webkit-transform: skewX(-10deg) !important;
    transform: skewX(-10deg) !important;
    line-height: 1;
  }
  header .download-cirriculum-callout:hover {
    filter: brightness(125%);
    -webkit-filter: brightness(125%);
    -moz-filter: brightness(125%);
    -o-filter: brightness(125%);
    -ms-filter: brightness(125%);
  }
  header .localization-link {
    float: left;
    margin-left: 15px;
    margin-top: 5px;
  }
  header .menu-main-menu-container,
  header .menu-main-menu-espanol-container {
    float: right;
    clear: right;
    margin-top: 25px;
  }
  header .menu-main-menu-container > ul > li,
  header .menu-main-menu-espanol-container > ul > li {
    float: left;
    padding: 0;
    margin-left: 45px;
  }
  header .menu-main-menu-container > ul > li a:hover,
  header .menu-main-menu-espanol-container > ul > li a:hover {
    color: #fff;
  }
  header .menu-main-menu-container > ul > li > a,
  header .menu-main-menu-espanol-container > ul > li > a {
    padding-bottom: 15px;
  }
  header .menu-main-menu-container .menu-item-has-children,
  header .menu-main-menu-espanol-container .menu-item-has-children {
    position: relative;
  }
  header .menu-main-menu-container .menu-item-has-children > a:after,
  header .menu-main-menu-espanol-container .menu-item-has-children > a:after {
    margin-left: 10px;
    margin-top: 3px;
    float: right;
    position: static;
  }
  header .menu-main-menu-container .menu-item-has-children:hover > a,
  header .menu-main-menu-espanol-container .menu-item-has-children:hover > a {
    color: #fff;
  }
  header .menu-main-menu-container .menu-item-has-children:hover .sub-menu,
  header .menu-main-menu-espanol-container .menu-item-has-children:hover .sub-menu {
    visibility: visible;
    opacity: 1;
    top: 40px;
    transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
    -moz-transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
    -webkit-transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
    -o-transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
  }
  header .menu-main-menu-container .sub-menu,
  header .menu-main-menu-espanol-container .sub-menu {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 90%;
    z-index: 10;
    width: 400px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 15px;
    background-color: #460b0c;
    transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
    -moz-transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
    -webkit-transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
    -o-transition: 200ms visibility ease, 150ms opacity ease, 200ms top ease;
  }
  header .menu-main-menu-container .sub-menu a,
  header .menu-main-menu-espanol-container .sub-menu a {
    margin-bottom: 10px;
    float: right;
    clear: both;
  }
  header .menu-main-menu-container .sub-menu li:last-child a,
  header .menu-main-menu-espanol-container .sub-menu li:last-child a {
    margin-bottom: 0;
  }
  header .menu-main-menu-container .menu li:last-child .sub-menu,
  header .menu-main-menu-espanol-container .menu li:last-child .sub-menu {
    left: auto;
    right: 0px;
  }
  header .menu-main-menu-espanol-container > ul > li {
    margin-left: 15px;
  }
}
@media screen and (min-width: 1020px) {
  header .menu-main-menu-espanol-container > ul > li {
    margin-left: 35px;
  }
}
header .download-cirriculum-callout-fixed {
  display: none;
  text-decoration: none;
  font-weight: 100;
  color: #faf0e6;
  font-size: 16px;
  opacity: .85;
  float: right;
  position: relative;
  padding: 0 15px;
  margin-top: 10px;
  margin-left: 15px;
}
header .download-cirriculum-callout-fixed:hover {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
@media screen and (min-width: 870px) {
  body.fixed-header {
    padding-top: 100px;
  }
  body.fixed-header header {
    padding-bottom: 2px !important;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 10;
    transform: translateY(-51px);
    -moz-transform: translateY(-51px);
    -webkit-transform: translateY(-51px);
    -o-transform: translateY(-51px);
  }
  body.fixed-header header.active {
    visibility: visible;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transition: 400ms transform ease;
    -moz-transition: 400ms transform ease;
    -webkit-transition: 400ms transform ease;
    -o-transition: 400ms transform ease;
  }
  body.fixed-header header .localization {
    display: none;
  }
  body.fixed-header header .menu-main-menu-container,
  body.fixed-header header .menu-main-menu-espanol-container {
    margin-top: 10px;
    clear: none;
    float: right;
  }
  body.fixed-header header .container {
    height: auto;
  }
  body.fixed-header header .logo-container {
    visibility: hidden;
  }
  body.fixed-header header .logo-container-fixed {
    display: block;
  }
  body.fixed-header header .logo-container-fixed img {
    height: 50px;
    width: 262px;
  }
  body.fixed-header header .menu-main-menu-container > ul > li,
  body.fixed-header header .menu-main-menu-espanol-container > ul > li {
    margin-left: 15px;
  }
  body.fixed-header header .download-cirriculum-callout-fixed {
    display: block;
  }
  body.fixed-header header .download-cirriculum-callout-fixed:before {
    content: " ";
    position: absolute;
    top: -10px;
    right: 0;
    bottom: -10px;
    left: 0;
    background: #630709;
    z-index: -1;
    transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
}
body.fixed-header.admin-bar header {
  top: 32px;
}
@media screen and (min-width: 1120px) {
  body.fixed-header header .download-cirriculum-callout-fixed {
    margin-left: 15px;
  }
  body.fixed-header header .menu-main-menu-container > ul > li {
    margin-left: 30px;
  }
  body.fixed-header header .menu-main-menu-espanol-container > ul > li {
    margin-left: 18px;
  }
}
.home {
  overflow-x: hidden;
}
.hero {
  width: 100%;
  float: left;
  background-size: 120%;
  background-repeat: no-repeat;
  position: relative;
}
.hero:after {
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}
.hero .hero-inner {
  padding: 70px 0 60px 0;
  color: #ffffff;
  font-family: 'Podkova', serif;
  font-size: 27px;
  letter-spacing: 1px;
  line-height: 36px;
  max-width: 475px;
  width: 92%;
  margin: auto;
  height: 230px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.hero .hero-inner h1 {
  font-size: 35px;
  position: relative;
  letter-spacing: 1px;
}
.hero .hero-inner em {
  font-family: 'Rock Salt', cursive;
  line-height: 36px;
  position: absolute;
  bottom: -10px;
  margin-left: 10px;
  font-style: italic;
  font-size: 30px;
}
.hero .sub-hero {
  position: relative;
  z-index: 1;
  padding: 35px 20px;
  color: #006675;
  background-image: url('img/bg/paper-light.jpg');
}
.hero .sub-hero h2 {
  font-family: 'Podkova', serif;
  font-size: 28px;
}
.hero .sub-hero p {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 10px;
}
.hero .sub-hero h3 {
  font-family: 'Rock Salt', cursive;
  margin-bottom: 10px;
}
.hero .sub-hero:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.hero .sub-hero .hero-callout-button {
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  text-decoration: none;
  color: #faf0e6;
  font-family: 'Podkova', serif;
  font-size: 23px;
  font-weight: 400;
  position: relative;
  z-index: 0;
  margin-bottom: 5px;
  letter-spacing: .75px;
}
.hero .sub-hero .hero-callout-button:first-child {
  margin-top: 30px;
}
.hero .sub-hero .hero-callout-button.film:before {
  background-color: #8f0005;
}
.hero .sub-hero .hero-callout-button.pledge:before {
  background-color: #32254c;
}
.hero .sub-hero .hero-callout-button.resources:before {
  background-color: #006675;
}
.hero .sub-hero .hero-callout-button img {
  height: 30px;
  width: auto;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -15px;
}
.hero .sub-hero .hero-callout-button:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
@media screen and (max-width: 869px) {
  .lang-es .hero .hero-inner {
    height: 265px;
  }
}
.lang-es .hero .hero-inner em {
  position: relative;
  float: right;
}
.lang-es .pledge-cta-callout .take-the-pledge .pledge-count .pledge-count-explination {
  width: 50%;
}
@media screen and (min-width: 870px) {
  .hero {
    background-size: cover;
    padding: 100px 0 200px 0;
  }
  .hero .hero-inner {
    padding: 0 10px;
    font-size: 245%;
    width: auto;
    margin: auto;
    max-width: 980px;
    height: auto;
    margin-bottom: 100px;
  }
  .hero .hero-inner em {
    font-size: 60px;
  }
  .hero .sub-hero {
    background-image: none !important;
    box-sizing: border-box;
    max-width: 980px;
    margin: auto;
    width: 100%;
    height: 295px;
  }
  .hero .sub-hero .sub-hero-content {
    float: left;
    width: 60%;
    padding: 40px 0;
    padding-right: 60px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
  }
  .hero .sub-hero .sub-hero-content h2 {
    font-style: italic;
    font-size: 30px;
  }
  .hero .sub-hero .sub-hero-content p {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .hero .sub-hero .sub-hero-content:after {
    content: " ";
    top: 0;
    bottom: 0;
    right: 0;
    width: 10000px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: -1;
    transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
  .hero .sub-hero .button-container {
    float: left;
    width: 40%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .hero .sub-hero .hero-callout-button {
    margin-bottom: 10px;
    padding: 20px 15px;
    width: 300px;
    height: 70px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
    transform: translateX(0) translateZ(0);
    position: absolute;
    white-space: nowrap;
  }
  .hero .sub-hero .hero-callout-button:first-child {
    margin-top: 0;
    top: 35px;
    width: auto;
    padding-left: 60px;
  }
  .hero .sub-hero .hero-callout-button.pledge,
  .hero .sub-hero .hero-callout-button:nth-child(2) {
    margin-left: 40px;
    top: 50%;
    margin-top: -35px;
  }
  .hero .sub-hero .hero-callout-button.resources,
  .hero .sub-hero .hero-callout-button:nth-child(3) {
    margin-left: -20px;
    margin-bottom: 0;
    bottom: 35px;
  }
  .hero .sub-hero .hero-callout-button:hover {
    transform: translateX(5px) translateZ(0);
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
  }
  .hero .sub-hero .hero-callout-button:hover:before {
    filter: brightness(75%);
    -webkit-filter: brightness(75%);
    -moz-filter: brightness(75%);
    -o-filter: brightness(75%);
    -ms-filter: brightness(75%);
  }
}
@media screen and (max-width: 869px) {
  .hero .sub-hero .hero-callout-button {
    max-width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
  }
  .hero .sub-hero .hero-callout-button:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  .hero .sub-hero .hero-callout-button:first-child img {
    display: none;
  }
}
.awards {
  padding: 10px 0;
  float: left;
  width: 100%;
  clear: both;
  background-image: url('img/bg/paper-dark.jpg');
  overflow: hidden;
}
.awards .awards-inner {
  padding: 20px 0;
  background-color: #460b0c;
  width: 100%;
}
.awards .awards-inner:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.awards .awards-inner .awards-image {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 2%;
  width: 25%;
  float: left;
  display: none;
}
.awards .awards-inner .awards-image:nth-child(4n+1) {
  clear: both;
}
.awards .awards-inner .awards-image:nth-child(-n+8) {
  display: inline-block;
}
@media screen and (max-width: 500px) {
  .awards .awards-inner .awards-image {
    padding: 2%;
    width: 50%;
    float: left;
    display: none;
  }
  .awards .awards-inner .awards-image:nth-child(2n+1) {
    clear: both;
  }
  .awards .awards-inner .awards-image:nth-child(-n+8) {
    display: none;
  }
  .awards .awards-inner .awards-image:nth-child(-n+4) {
    display: inline-block;
  }
}
@media screen and (min-width: 870px) {
  .awards {
    overflow: hidden;
  }
  .awards .awards-inner {
    text-align: center;
    white-space: nowrap;
    width: auto;
  }
  .awards .awards-inner .awards-image {
    float: none;
    padding: 0 8px;
    width: 190px;
    display: inline;
  }
}
.pledge-cta-callout {
  background-color: #32254c;
  background-image: url('img/bg/purple-diamond-light.png');
  clear: both;
  color: #ebf1ff;
}
.pledge-cta-callout .take-the-pledge {
  padding: 30px 0;
  background-color: rgba(0, 0, 0, 0.25);
  position: relative;
  clear: both;
}
.pledge-cta-callout .take-the-pledge:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.pledge-cta-callout .take-the-pledge img {
  width: 110px;
  display: block;
  margin: auto;
}
.pledge-cta-callout .take-the-pledge h1 {
  color: #ebf1ff;
  font-family: 'Podkova', serif;
  font-size: 36px;
  letter-spacing: .02em;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 10px;
}
.pledge-cta-callout .take-the-pledge p {
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 100;
  margin-bottom: 1.5em;
}
.pledge-cta-callout .take-the-pledge .pledge-count {
  margin-top: 40px;
  clear: both;
  float: left;
  width: 100%;
}
.pledge-cta-callout .take-the-pledge .pledge-count .count {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-block;
  text-align: left;
  font-family: 'Rock Salt', cursive;
  font-size: 30px;
  letter-spacing: 2px;
  float: left;
  padding-right: 20px;
}
.pledge-cta-callout .take-the-pledge .pledge-count .pledge-count-explination {
  float: left;
  width: 45%;
  line-height: 22px;
  margin-top: -7px;
}
.pledge-cta-callout .take-the-pledge a {
  display: block;
  text-decoration: none;
  clear: both;
  text-align: center;
  margin-top: 40px;
  float: left;
  width: 100%;
  font-family: 'Podkova', serif;
  font-size: 27px;
  color: #ebf1ff;
  position: relative;
  padding: 15px 0;
}
.pledge-cta-callout .take-the-pledge a:before {
  content: " ";
  position: absolute;
  bottom: -2px;
  right: 0;
  left: 0;
  background-image: url('img/sketch-line-bottom.png');
  background-position: center center;
  background-repeat: no-repeat;
  height: 5px;
}
.pledge-cta-callout .take-the-pledge a:after {
  content: " ";
  position: absolute;
  top: -2px;
  right: 0;
  left: 0;
  background-image: url('img/sketch-line-top.png');
  background-position: center center;
  background-repeat: no-repeat;
  height: 5px;
}
.pledge-cta-callout .see-the-support {
  padding: 45px 0;
  position: relative;
}
.pledge-cta-callout .see-the-support:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.pledge-cta-callout .see-the-support:before {
  content: " ";
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.25);
}
.pledge-cta-callout .see-the-support h2 {
  color: #ebf1ff;
  font-family: 'Podkova', serif;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: .5px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.pledge-cta-callout .see-the-support .pledge {
  padding: 5px 0;
  display: none;
}
.pledge-cta-callout .see-the-support .pledge a {
  text-decoration: none;
  color: #ad98c6;
  font-weight: 100;
  margin-left: 10px;
}
.pledge-cta-callout .see-the-support .pledge a span {
  font-family: 'Podkova', serif;
  font-size: 120%;
  margin-left: 5px;
}
.pledge-cta-callout .see-the-support .pledge a:hover {
  text-decoration: underline;
}
.pledge-cta-callout .see-the-support .pledge:nth-child(-n+5) {
  display: block;
}
@media screen and (min-width: 780px) {
  .pledge-cta-callout .take-the-pledge img {
    width: 20%;
    float: left;
    margin-right: 30px;
  }
  .pledge-cta-callout .take-the-pledge h1 {
    padding-top: 15px;
    margin-bottom: 10px;
    font-size: 45px;
  }
  .pledge-cta-callout .take-the-pledge .pledge-count {
    width: 35%;
    margin-left: 23%;
    float: left;
    box-sizing: border-box;
    padding-right: 10px;
    margin-top: 10px;
  }
  .pledge-cta-callout .take-the-pledge a {
    width: 30%;
    float: left;
    clear: none;
    margin-left: 5%;
    margin: 0;
    opacity: 1;
    transition: 200ms all ease;
    -o-transition: 200ms all ease;
  }
  .pledge-cta-callout .take-the-pledge a:after {
    transition: 200ms all ease;
    -o-transition: 200ms all ease;
  }
  .pledge-cta-callout .take-the-pledge a:before {
    transition: 200ms all ease;
    -o-transition: 200ms all ease;
  }
  .pledge-cta-callout .take-the-pledge a:hover {
    opacity: .7;
  }
  .pledge-cta-callout .see-the-support h2 {
    margin-top: 0;
  }
  .pledge-cta-callout .see-the-support .pledge {
    width: 50%;
    float: left;
    display: block;
    font-size: 110%;
  }
  .pledge-cta-callout .see-the-support .pledge a {
    margin-left: 15px;
  }
}
.social-band {
  padding: 10px 0 30px 0;
  background: url('img/bg/paper-light.jpg');
}
.social-band:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.social-band .social-images {
  margin-bottom: 25px;
  float: left;
  width: 100%;
}
.social-band .social-images .social-image {
  display: none;
  float: left;
  width: 25%;
  height: auto;
  background-position: 50% 25%;
  background-size: 100% auto;
  background-size: cover;
}
.social-band .social-images .social-image img {
  width: 100%;
  height: auto;
}
.social-band .social-images .social-image:nth-child(-n+4) {
  display: block;
}
.social-band .social-band-inner {
  text-align: center;
}
.social-band .social-band-inner .hashtags {
  margin-bottom: 25px;
}
.social-band .social-band-inner .hashtags a {
  font-family: 'Podkova', serif;
  color: #8f0005;
  text-decoration: none;
  font-size: 25px;
  margin-bottom: 25px;
  display: inline-block;
}
.social-band .social-band-inner .hashtags a:nth-of-type(2) {
  margin: 0;
}
.social-band .social-band-inner .hashtags a:hover {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
.social-band .social-band-inner span {
  font-family: 'Rock Salt', cursive;
  margin-top: 30px;
  margin-right: 10px;
}
.social-band .social-band-inner .connect-icons a {
  color: #8f0005;
  font-size: 0;
  margin-top: 25px;
}
.social-band .social-band-inner .connect-icons a .fa {
  font-size: 30px;
}
.social-band .social-band-inner .connect-icons a:hover {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
@media screen and (min-width: 870px) {
  .social-band {
    padding-bottom: 0;
  }
  .social-band .social-images {
    margin-bottom: 0;
  }
  .social-band .social-images .social-image {
    display: inline-block;
    width: 12.5%;
    background-position: center top;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
  }
  .social-band .social-band-inner .hashtags {
    float: left;
    width: 50%;
    margin-bottom: 0;
  }
  .social-band .social-band-inner .hashtags a {
    width: 50%;
    float: left;
    margin-bottom: 0;
  }
  .social-band .social-band-inner .hashtags br {
    display: none;
  }
  .social-band .social-band-inner > span {
    margin-left: 0;
    display: inline;
  }
  .social-band .container {
    padding: 55px 0;
  }
  .social-band .container > a {
    margin-right: 5px;
  }
}
@media screen and (min-width: 1875px) {
  .social-band .social-images .social-image {
    height: 300px;
  }
}
.host-a-screening-callout {
  float: left;
  clear: both;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 125px 0;
  background-size: cover;
  background-position: center center;
  overflow-x: hidden;
}
.host-a-screening-callout:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.lang-es .host-a-screening-callout-inner h2 {
  line-height: .8em;
}
.host-a-screening-callout-inner {
  float: left;
  clear: both;
  width: 100%;
  padding: 30px 0;
  text-align: center;
  position: relative;
  z-index: 0;
}
.host-a-screening-callout-inner:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.host-a-screening-callout-inner:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -10000px;
  bottom: 0;
  left: -10000px;
  z-index: -1;
  background-color: #faf0e6;
  opacity: .9;
}
.host-a-screening-callout-inner h2 {
  text-align: left;
  font-family: 'Podkova', serif;
  font-size: 25px;
  letter-spacing: .02em;
  font-weight: 700;
  margin-bottom: 10px;
  color: #006675;
}
.host-a-screening-callout-inner p {
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.host-a-screening-callout-inner h3 {
  font-family: 'Rock Salt', cursive;
}
.host-a-screening-callout-inner a {
  font-family: 'Podkova', serif;
  color: #faf0e6;
  font-size: 20px;
  letter-spacing: .5px;
  text-decoration: none;
  transform: translateX(0) translateZ(0);
  padding: 10px 20px;
  display: inline-block;
  margin: 20px 0 0;
  position: relative;
  z-index: 0;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.host-a-screening-callout-inner a:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.host-a-screening-callout-inner a:hover {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.host-a-screening-callout-inner a:hover:before {
  filter: brightness(75%);
  -webkit-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -o-filter: brightness(75%);
  -ms-filter: brightness(75%);
}
@media screen and (min-width: 870px) {
  .host-a-screening-callout {
    padding: 150px 0;
  }
  .host-a-screening-callout-inner {
    width: 60%;
    text-align: left;
    padding: 50px;
    padding-left: 0;
  }
  .host-a-screening-callout-inner:before {
    right: 0;
    transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
  .host-a-screening-callout-inner h2 {
    font-size: 50px;
    margin-bottom: 20px;
  }
  .host-a-screening-callout-inner p {
    font-size: 20px;
  }
  .host-a-screening-callout-inner a {
    margin-top: 20px;
    font-size: 25px;
    letter-spacing: 1.75px;
    position: absolute;
    right: -50px;
    bottom: -20px;
  }
}
.resources-callout {
  float: left;
  clear: both;
  width: 100%;
  background: url('img/bg/paper-dark.jpg');
  position: relative;
  padding: 40px 0;
}
.resources-callout:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  bottom: 10px;
  background-color: #fff8f1;
  opacity: .8;
}
.resources-callout .container {
  position: relative;
  z-index: 0;
}
.resources-callout h1 {
  font-family: 'Podkova', serif;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.resources-callout p {
  text-align: center;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 35px;
}
.resources-callout .resource-callout-link {
  transform: translateX(0) translateZ(0);
}
.resources-callout .resource-callout-link img {
  width: 183px;
  display: block;
  margin: auto;
}
.resources-callout .resource-callout-link .resource-link-button {
  position: relative;
  z-index: 1;
  display: block;
  width: 225px;
  margin: auto;
  margin-top: -20px;
  margin-bottom: 35px;
  text-align: center;
  text-decoration: none;
  font-family: 'Podkova', serif;
  font-size: 20px;
  letter-spacing: .5px;
  color: #faf0e6;
  padding: 10px 0;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.resources-callout .resource-callout-link .resource-link-button:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
  z-index: -1;
}
.resources-callout .resource-callout-link:hover .resource-link-button {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.resources-callout .resource-callout-link:hover .resource-link-button:before {
  filter: brightness(75%);
  -webkit-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -o-filter: brightness(75%);
  -ms-filter: brightness(75%);
}
@media screen and (min-width: 870px) {
  .resources-callout {
    padding-top: 80px;
  }
  .resources-callout h1 {
    font-size: 45px;
  }
  .resources-callout p {
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 50px;
  }
  .resources-callout .resource-callout-link {
    width: 300px;
    height: 265px;
    float: right;
    margin-bottom: 80px;
  }
  .resources-callout .resource-callout-link:hover img {
    opacity: .7;
    transition: 200ms opacity ease;
    -webkit-transition: 200ms opacity ease;
  }
  .resources-callout .resource-callout-link:nth-of-type(odd) {
    clear: left;
    margin-left: 13%;
  }
  .resources-callout .resource-callout-link:nth-child(-n+1) {
    clear: none;
    margin-right: 13%;
    margin-left: 0;
  }
  .resources-callout .resource-callout-link:nth-child(-n+2) {
    float: left;
  }
  .resources-callout .resource-callout-link img {
    width: 250px;
    transition: 200ms opacity ease;
    -webkit-transition: 200ms opacity ease;
  }
  .resources-callout .resource-callout-link .resource-link-button {
    width: 100%;
    font-size: 25px;
    margin-top: -35px;
  }
}
@media screen and (max-width: 797px) {
  .home .stats-callout-container {
    float: left;
    clear: both;
    width: 100%;
    overflow-x: hidden;
  }
  .home .stats-callout-container:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .home .stats-callout {
    margin-bottom: 0;
    display: block;
    float: left;
    clear: both;
    width: 100%;
    text-align: center;
    transform: translateX(0) translateZ(0);
  }
  .home .stats-callout:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .home .stats-callout .fact-callout {
    width: 235px;
    display: block;
    margin: auto;
    float: none;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 0 20px;
    transform: translateX(0) translateZ(0);
  }
  .home .stats-callout .fact-callout:before {
    left: 0;
    top: -55px;
  }
  .home .stats-callout .fact-callout:after {
    height: 0;
    border-style: solid;
    border-width: 13px 117.5px 0 117.5px;
    border-color: #00454f transparent transparent transparent;
    opacity: .5;
    left: 0;
    right: 0;
    bottom: -22px;
    top: 100%;
  }
  .home .stats-callout .fact-content {
    width: 90%;
    display: block;
    float: none;
    margin-top: 20px;
    margin-left: 0 !important;
    margin: auto !important;
    text-align: left;
  }
  .home .stats-callout .view-save-share {
    display: inline-block;
    margin-top: 40px;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    position: relative;
    float: none;
    clear: both;
  }
  .home .stats-callout .view-all {
    display: inline-block;
    margin-bottom: 20px;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    position: relative;
    float: none;
    clear: both;
  }
}
@media screen and (max-width: 869px) {
  .host-a-screening-callout-inner {
    max-width: 445px;
    margin: 0 auto;
    float: none;
  }
  .host-a-screening-callout-inner a {
    float: right;
  }
  .social-band .social-band-inner .hashtags a {
    display: inline;
    float: none;
    margin: 1em;
  }
  .social-band .social-band-inner .hashtags br {
    display: none;
  }
}
@media screen and (max-width: 869px) and (min-width: 680px) {
  .resources-callout .resource-callout-link {
    display: inline-block;
    width: 50%;
    float: left;
  }
  .resources-callout .resource-callout-link:nth-child(2n+1) {
    clear: both;
  }
}
@media screen and (min-width: 870px) and (max-width: 2000px) {
  .awards {
    position: relative;
    height: 134px;
  }
  .awards .awards-inner {
    width: 2000px;
    margin-left: -1000px;
    position: absolute;
    left: 50%;
    top: 10px;
  }
}
.home .stats-callout-container {
  float: left;
  clear: both;
  width: 100%;
  overflow-x: hidden;
  background-color: #014c57;
}
.home .stats-callout-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.home .stats-callout {
  margin-bottom: 0;
  padding: 55px 0 35px 0;
}
.home .stats-callout .fact-content {
  margin-left: 5%;
}
.home .stats-callout .fact-content h6 {
  font-size: 13px;
}
#watch-film-popup {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.7);
  overflow: scroll;
  z-index: 100000;
}
#watch-film-popup .container {
  position: relative;
  width: 80%;
  padding: 100px 140px;
  margin: 10% auto;
  background-image: url('img/bg/paper-light.jpg');
  float: none;
}
#watch-film-popup .container .close-watch-popup {
  position: absolute;
  top: 10px;
  right: 3px;
}
#watch-film-popup .container .close-watch-popup .fa {
  color: #952124;
  font-size: 30px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}
#watch-film-popup .container .close-watch-popup:hover .fa {
  color: #460b0c;
}
#watch-film-popup .container h2 {
  font-family: 'Podkova', serif;
  font-size: 30px;
  font-style: italic;
  font-weight: 700;
}
#watch-film-popup .container p {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 400;
  margin: 0;
}
#watch-film-popup .container p i {
  font-style: italic;
  font-weight: 100;
}
#watch-film-popup .container .button-container {
  width: 100%;
  margin-top: 20px;
  margin-left: 100px;
  display: block;
  height: 310px;
  position: relative;
}
#watch-film-popup .container .button-container .hero-callout-button {
  width: auto;
  font-size: 29px;
  padding: 20px 20px 20px 66px;
}
#watch-film-popup .container .button-container .hero-callout-button:first-child {
  margin-top: 0;
  top: 35px;
}
#watch-film-popup .container .button-container .hero-callout-button:nth-child(2) {
  margin-left: 40px;
  top: 50%;
  margin-top: -35px;
}
#watch-film-popup .container .button-container .hero-callout-button:nth-child(3) {
  margin-left: -20px;
  margin-bottom: 0;
  bottom: 35px;
}
@media screen and (max-width: 869px) {
  #watch-film-popup .container .button-container .hero-callout-button:first-child {
    margin-top: 0;
    top: auto;
  }
  #watch-film-popup .container .button-container .hero-callout-button.pledge,
  #watch-film-popup .container .button-container .hero-callout-button:nth-child(2) {
    top: auto;
    margin-top: auto;
  }
  #watch-film-popup .container .button-container .hero-callout-button.resources,
  #watch-film-popup .container .button-container .hero-callout-button:nth-child(3) {
    margin-left: -20px;
    margin-bottom: 0;
    bottom: auto;
  }
}
@media screen and (max-width: 991px) {
  #watch-film-popup .container {
    padding: 50px 70px;
  }
  #watch-film-popup .container .button-container {
    margin-top: 20px;
    margin-left: 60px;
  }
  #watch-film-popup .container .button-container .hero-callout-button {
    width: auto;
    font-size: 26px;
    padding: 20px 20px 20px 66px;
  }
  #watch-film-popup .container .button-container .hero-callout-button:nth-child(2) {
    margin-left: 20px;
  }
  #watch-film-popup .container .button-container .hero-callout-button:nth-child(3) {
    margin-left: -10px;
  }
}
@media screen and (max-width: 797px) {
  #watch-film-popup .container {
    padding: 25px 35px;
  }
  #watch-film-popup .container .button-container {
    margin-top: 20px;
    margin-left: 0;
  }
  #watch-film-popup .container .button-container .hero-callout-button {
    width: 100%;
    font-size: 20px;
    padding: 10px 0 10px 0;
  }
  #watch-film-popup .container .button-container .hero-callout-button img {
    display: none;
  }
  #watch-film-popup .container .button-container .hero-callout-button:nth-child(2) {
    margin-left: 0;
  }
  #watch-film-popup .container .button-container .hero-callout-button:nth-child(3) {
    margin-left: 0;
  }
}
@media screen and (max-width: 797px) {
  #watch-film-popup .container {
    padding: 25px 35px;
    width: 100%;
  }
  .fancybox-wrap {
    top: 50px !important;
  }
}
footer .hero {
  padding: 0;
}
footer .hero .sub-hero {
  padding: 0;
  height: 0;
}
body.blurred {
  overflow: hidden;
}
body.blurred > div,
body.blurred > header {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
body.blurred > div.fancybox-wrap,
body.blurred > div.fancybox-overlay,
body.blurred #watch-film-popup,
body.blurred #pledge-welcome-container {
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  -o-filter: blur(0);
  -ms-filter: blur(0);
  filter: blur(0);
}
.header-image {
  float: left;
  clear: both;
  width: 100%;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  background-color: #ffffff;
  height: 160px;
}
.header-image:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
@media screen and (min-width: 798px) {
  .header-image {
    height: 250px;
  }
}
.page-content {
  padding-top: 35px;
  padding-bottom: 45px;
  position: relative;
}
.page-content:before {
  content: " ";
  position: absolute;
  bottom: 0px;
  right: -10000px;
  left: -10000px;
  height: 10px;
  background-image: url('img/bg/paper-dark.jpg');
}
.page-content h1 {
  color: #006675;
  font-family: 'Podkova', serif;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  clear: both;
}
.page-content h2 {
  color: #006675;
  font-family: 'Podkova', serif;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 15px;
  clear: both;
  float: left;
  width: 100%;
}
.page-content h3 {
  font-family: 'Rock Salt', cursive;
  font-size: 25px;
  line-height: 30px;
  margin: 25px 0 15px 0;
  clear: both;
  float: left;
  width: 100%;
}
.page-content h4 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 100;
  float: left;
  width: 100%;
  margin: 20px 0 10px 0;
}
.page-content h5 {
  font-family: 'Podkova', serif;
  font-size: 20px;
  margin: 20px 0 15px;
  float: left;
  width: 100%;
}
.page-content h6 {
  font-family: 'Podkova', serif;
  font-size: 16px;
  margin: 20px 0 15px;
  float: left;
  width: 100%;
  color: #5d5d5d;
}
.page-content ul {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style: disc;
}
.page-content ul ul {
  list-style: square;
  padding-left: 40px;
  margin-bottom: 0;
}
.page-content ol {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style: decimal;
}
.page-content ol ol {
  list-style: upper-alpha;
  padding-left: 40px;
  margin-bottom: 0;
}
.page-content hr {
  position: relative;
  border: 0;
  margin: 20px 0 40px 0;
  float: left;
  width: 100%;
  clear: both;
}
.page-content hr:before {
  content: " ";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 20px;
  background-image: url('img/sketch-line-blue.png');
  background-size: auto 19px;
  background-position-y: bottom;
  background-position-x: right;
}
.page-content hr.gray:before {
  height: 10px;
  background-image: url('img/sketch-line-gray.png');
}
.page-content pre {
  white-space: normal;
  font-family: monospace;
  opacity: .9;
  margin-bottom: 25px;
}
.page-content p {
  margin-top: 10px;
  margin-bottom: 20px;
  letter-spacing: .02em;
}
.page-content p:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.page-content strong a:after {
  content: "»";
  font-family: 'Podkova', serif;
  margin-left: 10px;
}
.page-content a {
  color: #8f0005;
  text-decoration: none;
}
.page-content a:hover {
  text-decoration: underline;
}
.page-content strong {
  font-weight: 800;
}
.page-content em {
  font-style: italic;
}
.page-content img {
  display: block;
  max-width: 100%;
  margin-bottom: 15px;
  height: auto;
}
.page-content img.aligncenter {
  margin: auto;
  margin-bottom: 30px;
  height: auto;
}
.page-content blockquote {
  padding-left: 40px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
  color: #666666;
  font-style: italic;
}
.button {
  font-family: 'Podkova', serif;
  color: #faf0e6;
  letter-spacing: .5px;
  text-decoration: none;
  padding: 10px;
  float: left;
  margin: 5px 5px 0 0;
  position: relative;
  z-index: 0;
}
.button:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.button:last-child {
  margin: 5px 0 0 0;
}
.button:hover {
  color: #faf0e6;
  text-decoration: none;
}
.button:hover:before {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
a.button {
  font-family: 'Podkova', serif;
  color: #faf0e6;
  letter-spacing: .5px;
  text-decoration: none;
  padding: 10px;
  float: left;
  margin: 5px 5px 0 0;
  position: relative;
  z-index: 0;
}
a.button:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
a.button:last-child {
  margin: 5px 0 0 0;
}
a.button:hover {
  color: #faf0e6;
  text-decoration: none;
}
a.button:hover:before {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
@media screen and (min-width: 798px) {
  .page-content {
    padding-top: 40px;
    padding-bottom: 55px;
  }
  .page-content h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .page-content h2 {
    font-size: 30px;
    letter-spacing: 1px;
  }
  .page-content hr:before {
    background-size: cover;
    background-position-x: left;
    background-repeat-x: no-repeat;
  }
  .page-content p {
    font-size: 19px;
  }
  .page-content .button {
    font-size: 25px;
    letter-spacing: 1.35px;
    margin-right: 10px;
  }
  .page-content img.alignleft {
    float: left;
    margin: 0 30px 20px 0;
  }
  .page-content img.alignright {
    float: right;
    margin-left: 30px;
    margin-bottom: 10px;
  }
}
.page-template-default .page-content p:after {
  clear: none;
}
.cta-button-container {
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 30px 0 20px 0;
}
.cta-button-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.cta-button-container .button {
  display: inline-block;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  letter-spacing: 1px;
  color: #faf0e6;
  font-size: 18px;
  float: none;
  padding: 10px 30px;
}
.cta-button-container .button:after {
  content: "»";
  font-family: 'Podkova', serif;
  font-size: 25px;
  margin-left: 10px;
}
@media screen and (min-width: 798px) {
  .cta-button-container .button {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
  }
  .cta-button-container .button:hover {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
    transition: 200ms transform ease;
    -webkit-transition: 200ms transform ease;
    -moz-transition: 200ms transform ease;
    -o-transition: 200ms transform ease;
  }
}
.page-content.resources-template h2 {
  padding-top: 40px;
  margin: 0;
  margin-top: 40px;
  clear: none;
  position: relative;
}
.page-content.resources-template h2:before {
  content: " ";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 20px;
  background-image: url('img/sketch-line-blue.png');
  background-size: auto 19px;
  background-position-y: bottom;
  background-position-x: right;
}
.page-content.resources-template h2 + .wells-fargo-callout {
  margin-top: 30px;
}
.page-content.resources-template h3 {
  margin: 0;
  margin-top: 30px;
  font-size: 18px;
}
.page-content.resources-template .top-callout-buttons:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
@media screen and (min-width: 798px) {
  .page-content.resources-template h2:before {
    left: -60px;
    right: -60px;
    background-size: cover;
    background-position-x: left;
    background-repeat-x: no-repeat;
  }
}
.resource-section {
  margin-top: 25px;
  float: left;
  clear: both;
  width: 100%;
}
.resource-section:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.top-callout-buttons .button {
  font-size: 22px;
}
.resource {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  background: #ffffff;
  text-align: center;
  text-decoration: none;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.resource:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.resource span {
  display: block;
  margin-top: 10px;
  font-size: 20px;
  color: #8f0005;
  font-weight: 600;
}
.resource img {
  display: inline-block;
  max-width: 100%;
}
@media screen and (min-width: 798px) {
  .resource {
    float: left;
    width: 31%;
    margin-right: 3%;
    height: 280px;
    position: relative;
    opacity: 1;
    transition: 200ms opacity ease;
    -webkit-transition: 200ms opacity ease;
    -o-transition: 200ms opacity ease;
    -moz-transition: 200ms opacity ease;
  }
  .resource:nth-child(3n) {
    margin-right: 0;
  }
  .resource:hover {
    opacity: .7;
    transition: 400ms opacity ease;
    -webkit-transition: 200ms opacity ease;
    -o-transition: 200ms opacity ease;
    -moz-transition: 200ms opacity ease;
  }
  .resource span {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
@media screen and (min-width: 560px) and (max-width: 797px) {
  .resource {
    float: left;
    width: 48%;
    margin-right: 4%;
    height: 280px;
    position: relative;
    opacity: 1;
    transition: 200ms opacity ease;
    -webkit-transition: 200ms opacity ease;
    -o-transition: 200ms opacity ease;
    -moz-transition: 200ms opacity ease;
  }
  .resource:hover {
    opacity: .7;
    transition: 400ms opacity ease;
    -webkit-transition: 200ms opacity ease;
    -o-transition: 200ms opacity ease;
    -moz-transition: 200ms opacity ease;
  }
  .resource span {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    text-align: center;
  }
  .resource:nth-child(2n) {
    margin-right: 0;
  }
}
.wells-fargo-callout {
  float: left;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 20px;
  clear: both;
  position: relative;
}
.wells-fargo-callout:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.wells-fargo-callout:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #d9d9d6;
}
.wells-fargo-callout img {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 64px;
  height: 64px;
}
.wells-fargo-callout .wells-fargo-callout-content {
  position: relative;
}
.wells-fargo-callout .wells-fargo-callout-content p {
  margin: 0;
}
.wells-fargo-callout .wells-fargo-callout-content:before {
  content: " ";
  float: left;
  height: 64px;
  width: 64px;
  margin: 0 25px 20px 0;
}
.wells-fargo-callout a {
  font-family: 'Podkova', serif;
  font-size: 20px;
  letter-spacing: 1px;
  color: #8f0005;
  text-decoration: none;
  padding: 5px 20px;
  float: right;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0) translateZ(0);
  position: relative;
  z-index: 0;
}
.wells-fargo-callout a:before {
  content: " ";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #f2f2f2;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.wells-fargo-callout a:hover {
  text-decoration: none;
  transform: translateX(5px) translateZ(0);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.wells-fargo-callout a:hover:before {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
@media screen and (min-width: 798px) {
  .wells-fargo-callout {
    padding: 30px 50px;
  }
  .wells-fargo-callout:before {
    transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
  .wells-fargo-callout img {
    position: relative;
    float: left;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
  }
  .wells-fargo-callout .wells-fargo-callout-content {
    position: relative;
    float: left;
    width: 80%;
    padding-left: 4%;
  }
  .wells-fargo-callout .wells-fargo-callout-content:before {
    content: none;
  }
  .wells-fargo-callout a {
    position: absolute;
    z-index: 0;
    bottom: 20px;
    right: 20px;
  }
}
.resources-bottom-callout-section {
  background-size: auto 100%;
  padding: 45px 0;
}
.resources-bottom-callout-section .container > img {
  max-width: 165px;
  display: block;
  margin: auto;
}
.bottom-callout-content h1 {
  font-family: 'Podkova', serif;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 1.75px;
  margin: 20px 0 15px 0;
}
.bottom-callout-content h1 br {
  display: none;
}
a.bottom-callout-button {
  font-family: 'Podkova', serif;
  font-size: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  color: inherit;
  padding: 10px 15px;
  position: relative;
  display: inline-block;
  margin-top: 40px;
}
a.bottom-callout-button:before {
  content: " ";
  position: absolute;
  top: -2px;
  right: 0;
  left: 0;
  background-image: url('img/sketch-line-top.png');
  background-position: center center;
  background-repeat: no-repeat;
  height: 5px;
  opacity: .8;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
a.bottom-callout-button:after {
  content: " ";
  position: absolute;
  bottom: -2px;
  right: 0;
  left: 0;
  background-image: url('img/sketch-line-bottom.png');
  background-position: center center;
  background-repeat: no-repeat;
  height: 5px;
  opacity: .8;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
a.bottom-callout-button:hover {
  filter: brightness(75%);
  -webkit-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -o-filter: brightness(75%);
  -ms-filter: brightness(75%);
}
@media screen and (min-width: 798px) {
  .resources-bottom-callout-section .container > img {
    float: left;
    width: 15%;
  }
  .bottom-callout-content {
    float: left;
    width: 85%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-left: 40px;
  }
  .bottom-callout-content em {
    font-style: italic;
  }
  .bottom-callout-content h1 {
    font-size: 40px;
    line-height: 45px;
    margin-top: 0;
  }
  .bottom-callout-content h1 br {
    display: block;
  }
  .bottom-callout-content p {
    font-size: 18px;
    letter-spacing: .5px;
  }
  a.bottom-callout-button {
    clear: both;
    float: right;
  }
}
.press-and-media-template {
  margin-top: 80px;
  padding-top: 0;
}
.press-and-media-template h2 {
  margin-bottom: 20px;
}
@media screen and (min-width: 798px) {
  .press-and-media-template h2 {
    margin-bottom: 40px;
  }
}
.media-kit {
  float: left;
  clear: both;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 20px 0;
  padding: 25px 20px;
  position: relative;
  z-index: 0;
  color: #8f0005;
  text-align: center;
}
.media-kit:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.media-kit:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: .5;
  background-color: #e0dad5;
}
.media-kit h1 {
  font-family: 'Rock Salt', cursive;
  font-size: 25px;
  color: inherit;
  margin-bottom: 10px;
}
.media-kit .description {
  font-size: 18px;
  display: block;
}
.media-kit .sub-description {
  font-weight: 100;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}
.media-kit > a {
  color: inherit;
  font-size: 40px;
}
@media screen and (min-width: 798px) {
  .press-and-media-template {
    position: relative;
    margin-top: 50px;
  }
  .media-kit {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    width: auto;
  }
  .media-kit:before {
    transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -o-transform: skewX(10deg);
  }
}
.press-releases {
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 35px;
}
.press-releases:after {
  content: " ";
  height: 10px;
  visibility: visible;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-position-x: left;
  background-image: url('img/sketch-line-gray.png');
  background-size: cover;
}
.press-release {
  display: block;
  clear: both;
  font-size: 18px;
  text-decoration: none;
  color: #8f0005;
  margin-left: 45px;
  margin-bottom: 20px;
  position: relative;
}
.press-release:before {
  content: " ";
  position: absolute;
  left: -45px;
  top: 3px;
  width: 30px;
  height: 30px;
  float: left;
  background-image: url('img/press-release-icon.png');
  background-size: cover;
}
@media screen and (min-width: 798px) {
  .press-releases {
    margin-bottom: 20px;
  }
  .press-release {
    width: 60%;
    margin-left: 70px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .press-release:before {
    left: -70px;
    top: -3px;
    width: 50px;
    height: 50px;
  }
}
.news-article {
  display: block;
  clear: both;
  margin-left: 45px;
  margin-bottom: 20px;
  position: relative;
}
.news-article a {
  font-size: 18px;
  text-decoration: none;
  color: #8f0005;
}
.news-article span {
  display: block;
}
.news-article:before {
  content: " ";
  position: absolute;
  left: -45px;
  top: 3px;
  width: 30px;
  height: 30px;
  float: left;
  background-image: url('img/news-icon.png');
  background-size: cover;
}
@media screen and (min-width: 798px) {
  .news-article {
    margin-left: 70px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  .news-article a {
    float: left;
    margin-bottom: 5px;
  }
  .news-article span {
    clear: both;
  }
  .news-article:before {
    left: -70px;
    top: -3px;
    width: 50px;
    height: 50px;
  }
}
.gform_body label {
  font-size: 18px;
}
.gform_body input[type="text"],
.gform_body textarea {
  border: 0;
  width: 100%;
  padding: 10px;
  background-color: #fffaf5;
  border-left: 2px solid #006675;
  border-bottom: 2px solid #006675;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.gform_body input[type="text"]:focus,
.gform_body textarea:focus {
  outline: none;
}
.gform_body input[type="checkbox"] {
  border: 0;
  background-color: #fffaf5;
  border-left: 2px solid #006675;
  border-bottom: 2px solid #006675;
}
.gform_body .ginput_container {
  float: left;
  clear: both;
  width: 100%;
}
.gform_body .gfield {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}
.gform_body ul.gform_fields {
  display: block;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
}
.gform_body ul.gfield_checkbox {
  list-style: none;
  padding: 0;
  margin: 0;
}
.gform_body ul.gfield_checkbox label {
  font-size: 14px;
}
.gform_body .gfield_checkbox {
  position: relative;
  cursor: pointer;
}
.gform_body .gfield_checkbox input {
  opacity: 0;
  margin: 0;
  border: 0;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.gform_body .gfield_checkbox label {
  cursor: pointer;
  margin-left: 10px;
}
.gform_body .gfield_checkbox:before {
  content: " ";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  background-color: #fffaf5;
  top: 50%;
  margin-top: -10px;
  border-left: 2px solid #006675;
  border-bottom: 2px solid #006675;
  text-align: center;
}
.gform_body .gfield_checkbox.checked:before {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.gform_body .gfield_checkbox .gfield_checkbox label {
  margin-left: 10px;
}
.gform_footer {
  position: relative;
  z-index: 1;
  float: right;
}
.gform_footer:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #8f0005;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.gform_footer img {
  display: none;
}
.gform_footer .gform_button {
  font-family: 'Podkova', serif;
  font-size: 18px;
  margin: 0;
  border: 0;
  padding: 10px 20px;
  position: relative;
  z-index: 0;
  background: none;
  cursor: pointer;
}
.gform_footer .gform_button:focus {
  outline: none;
}
.required-fields {
  font-size: 14px;
}
.validation_message {
  clear: both;
  color: #8f0005;
}
.validation_error {
  clear: both;
  margin-bottom: 15px;
  color: #8f0005;
}
@media screen and (min-width: 798px) {
  .gform_body ul.gfield_checkbox label {
    font-size: 16px;
  }
  .required-fields {
    font-size: 16px;
  }
}
::-webkit-input-placeholder {
  color: #555;
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #555;
  opacity: 1;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #555;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #555;
  opacity: 1;
}
#field_1_7 .gfield_required {
  display: none;
}
@media screen and (min-width: 798px) {
  #gform_wrapper_1 {
    max-width: 70%;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    float: left;
    position: relative;
  }
  .gform_body ul {
    float: left;
    clear: both;
    width: 100%;
  }
  .gform_body ul:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  #field_1_1,
  #field_1_2,
  #field_1_3,
  #field_1_4 {
    width: 50%;
    float: left;
    padding-right: 2%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  #field_1_5,
  #field_1_6 {
    float: right;
    width: 50%;
    padding-left: 2%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  #field_1_5 textarea,
  #field_1_6 textarea {
    min-height: 195px;
    max-width: 100%;
  }
  label#label_1_7_1 {
    font-weight: 100;
    font-size: 20px;
  }
  span.required-fields {
    font-weight: 100;
    font-size: 18px;
  }
  .gform_footer {
    position: absolute;
    z-index: 1;
    right: 5px;
    bottom: 41px;
  }
}
.get-the-tools {
  float: left;
  clear: both;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 35px 0;
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}
.get-the-tools:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.get-the-tools:before {
  content: " ";
  height: 10px;
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  background-image: url('img/sketch-line-gray.png');
  background-size: cover;
  background-position-x: right;
}
.get-the-tools:after {
  visibility: visible;
  height: 10px;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  background-image: url('img/sketch-line-gray.png');
  background-size: cover;
  background-position-x: right;
}
.get-the-tools h2 {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}
.get-the-tools p {
  text-align: left;
}
.get-the-tools ul {
  text-align: left;
}
.get-the-tools .attachment {
  text-align: left;
  color: #8f0005;
  display: block;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 20px;
}
.get-the-tools .attachment span {
  font-family: 'Podkova', serif;
}
.get-the-tools a.button,
.get-the-tools .button:last-child {
  float: none;
  font-size: 20px;
  padding: 10px 30px;
  letter-spacing: 1.5px;
  display: inline-block;
  margin-top: 20px;
}
@media screen and (min-width: 798px) {
  .get-the-tools {
    text-align: left;
  }
  .get-the-tools:after {
    background: none;
  }
  .get-the-tools img.alignleft {
    margin-top: .25em;
  }
  .get-the-tools p:after {
    clear: none;
  }
}
.stats-badges {
  display: none;
}
@media screen and (min-width: 798px) {
  .stats-badges {
    display: block;
    float: left;
    width: 30%;
    padding-left: 85px;
    box-sizing: border-box;
    text-align: center;
  }
  .stats-badges .stats-badge {
    margin-bottom: 35px;
  }
  .stats-badges .stats-badge img {
    width: 145px;
    display: block;
    margin: auto;
  }
  .stats-badges .stats-badge span {
    font-size: 15px;
    letter-spacing: .35px;
    margin-top: 5px;
  }
}
.reaction {
  float: left;
  clear: both;
  width: 100%;
  padding-top: 40px;
  position: relative;
  padding-top: 35px;
  margin-bottom: 40px;
}
.reaction:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.reaction:before {
  content: " ";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 20px;
  background-image: url('img/sketch-line-blue.png');
  background-size: auto 19px;
  background-position-y: bottom;
  background-position-x: right;
}
.reaction:first-child {
  padding-top: 0;
}
.reaction:first-child:before {
  content: none;
}
.reaction p {
  font-size: 18px;
  font-style: italic;
  color: #006675;
  padding-left: 40px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
}
.reaction p:before {
  content: "\201C";
  color: #c2b6ab;
  opacity: .5;
  font-family: 'Podkova', serif;
  font-size: 70px;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 3;
  width: 50px;
  font-style: normal;
}
.reaction span {
  margin-left: 40px;
  display: block;
}
.reaction span.author-title {
  font-weight: 100;
  letter-spacing: 1;
}
@media screen and (min-width: 798px) {
  .reactions {
    width: 70%;
    float: left;
  }
  .reaction {
    margin-bottom: 30px;
  }
  .reaction:before {
    background-position-x: left;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .reaction p {
    font-size: 20px;
    padding-left: 75px;
    margin-bottom: 10px;
  }
  .reaction p:before {
    font-size: 125px;
    top: 30px;
  }
  .reaction span {
    margin-left: 75px;
    font-size: 18px;
  }
  .reaction span.author-title {
    line-height: 14px;
  }
}
.about-the-film-content img {
  margin-top: 20px;
}
.about-section {
  float: left;
  clear: both;
  width: 100%;
  margin-top: 40px;
}
.about-section:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.about-section .image-container {
  float: left;
  clear: both;
  width: 100%;
  position: relative;
  width: auto;
  margin-bottom: 20px;
}
.about-section .image-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.about-section .image-container img {
  float: left;
}
.about-section .image-container .caption {
  font-family: 'Rock Salt', cursive;
  color: #ffffff;
  text-align: center;
  letter-spacing: 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 10px 20px;
  display: block;
  width: 225px;
  margin-left: -112.5px;
  position: absolute;
  left: 50%;
  bottom: -10px;
  z-index: 0;
}
.about-section .image-container .caption:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: #006675;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
}
.about-section .about-section-content {
  clear: both;
}
.bottom-callout-section {
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  color: #460b0c;
  font-weight: 800;
  letter-spacing: 1px;
}
.bottom-callout-section:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.cast-member .about-section-content h2 {
  display: none;
}
@media screen and (min-width: 798px) {
  .about-the-film-page-template .page-content > h1 {
    margin-bottom: 20px;
  }
  .about-the-film-page-template .about-the-film-content {
    float: left;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .about-the-film-page-template .about-the-film-content h2 {
    margin-top: 0;
    width: auto;
    clear: none;
    float: none;
  }
  .about-the-film-page-template .about-the-film-content > img {
    float: left;
    width: 30%;
    margin-right: 35px;
    margin-top: 0;
  }
  .about-the-film-page-template .about-the-film-content p:after {
    content: none;
  }
  .about-section {
    margin-bottom: 20px;
  }
  .about-section .image-container {
    width: 30%;
    float: left;
    clear: left;
  }
  .about-section .about-section-content {
    width: 70%;
    float: left;
    padding-left: 35px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    clear: none;
  }
  .about-section .about-section-content h2 {
    margin-top: 0;
  }
  .cast-member .about-section-content h2 {
    display: block;
  }
}
.about-the-film-page-template hr {
  margin: 20px 0 40px 0;
}
.page-template-page-newpledge .page-content,
.single-pledge .page-content {
  padding-left: 0;
  padding-right: 0;
}
.page-template-page-newpledge .page-content h1,
.single-pledge .page-content h1 {
  color: #5b3d8a;
  letter-spacing: .02em;
  font-weight: 700;
}
.page-template-page-newpledge .page-content p,
.single-pledge .page-content p {
  font-size: 20px;
}
.page-template-page-newpledge #pledge_count_container,
.single-pledge #pledge_count_container {
  width: 100%;
  text-align: center;
  padding: 30px 0;
  text-decoration: none;
  color: #faf0e6;
  font-family: 'Podkova', serif;
  font-size: 23px;
  font-weight: 400;
  position: relative;
  z-index: 0;
  margin-bottom: 5px;
  letter-spacing: .75px;
}
.page-template-page-newpledge #pledge_count_container .pledge_count_upper,
.single-pledge #pledge_count_container .pledge_count_upper {
  display: block;
  font-family: 'rock salt';
  font-size: 30px;
  color: #fff;
  margin-bottom: 12px;
}
.page-template-page-newpledge #pledge_count_container .pledge_count_lower,
.single-pledge #pledge_count_container .pledge_count_lower {
  font-family: 'source sans pro', sans-serif;
  font-size: 18px;
  color: #fff;
}
.page-template-page-newpledge #pledge_count_container:before,
.single-pledge #pledge_count_container:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
  background-color: #32254c;
  background-image: url(img/bg/purple-diamond-light.png);
}
.page-template-page-newpledge #primaryPostForm,
.single-pledge #primaryPostForm {
  font-family: 'Podkova', serif;
  font-size: 30px;
  /*
      @white: #ffffff;
      @black: #434343;
      @red: #8f0005;
      @blue: #006675;
      @cream: #faf0e6;
      @purple: #32254c;
      @dark-red: #460b0c;
      */
}
.page-template-page-newpledge #primaryPostForm .row,
.single-pledge #primaryPostForm .row {
  margin-bottom: 1em;
}
.page-template-page-newpledge #primaryPostForm .row:after,
.single-pledge #primaryPostForm .row:after {
  clear: both;
}
.page-template-page-newpledge #primaryPostForm ul,
.single-pledge #primaryPostForm ul {
  list-style: none;
  padding: 0;
}
.page-template-page-newpledge #primaryPostForm ul li,
.single-pledge #primaryPostForm ul li {
  font-family: 'Podkova', serif;
  font-size: 22px;
  font-weight: 300;
}
.page-template-page-newpledge #primaryPostForm h2,
.single-pledge #primaryPostForm h2 {
  color: #5b3d8a;
  letter-spacing: .02em;
  font-weight: 700;
  line-height: 30px;
  margin: 20px 0;
}
.page-template-page-newpledge #primaryPostForm h3,
.single-pledge #primaryPostForm h3 {
  font-family: 'Podkova', serif;
  font-size: 30px;
  margin: 1em 0 0 0;
}
.page-template-page-newpledge #primaryPostForm h4,
.single-pledge #primaryPostForm h4 {
  font-family: 'Rock Salt', cursive;
  font-size: 16px;
  color: #5b3d8a;
  text-transform: none;
  letter-spacing: .03em;
  line-height: 30px;
  margin: 30px 0 10px 0;
}
.page-template-page-newpledge #primaryPostForm h4.smaller-top-margin,
.single-pledge #primaryPostForm h4.smaller-top-margin {
  margin-top: 17px;
}
.page-template-page-newpledge #primaryPostForm input[type="text"],
.single-pledge #primaryPostForm input[type="text"],
.page-template-page-newpledge #primaryPostForm input[type="email"],
.single-pledge #primaryPostForm input[type="email"] {
  color: #000;
  opacity: 1;
  background-color: #fffaf5;
  font-family: 'Podkova', serif;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  max-width: 90%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ouline: none;
  box-shadow: none;
  border-radius: none;
  border-top: none;
  border-right: none;
  border-left: 2px solid #7a55ac;
  border-bottom: 2px solid #7a55ac;
  padding: 2px .5em;
}
.page-template-page-newpledge #primaryPostForm select,
.single-pledge #primaryPostForm select {
  background-color: #fffaf5;
  font-family: 'Podkova', serif;
  font-size: 25px;
  width: 100%;
  line-height: 36px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ouline: none;
  box-shadow: none;
  border-radius: none;
  border-top: none;
  border-right: none;
  border-left: 2px solid #7a55ac;
  border-bottom: 2px solid #7a55ac;
  border-radius: 0;
  padding: 2px .5em;
  background-image: url(img/pledge/caret-down-purple.png);
  background-repeat: no-repeat;
  background-size: 15px 9px;
  background-position: 98% 50%;
}
.page-template-page-newpledge #primaryPostForm .required.error,
.single-pledge #primaryPostForm .required.error {
  border: 2px solid #f00;
}
.page-template-page-newpledge #primaryPostForm #pledge-choices-container ul li,
.single-pledge #primaryPostForm #pledge-choices-container ul li {
  margin-bottom: .25em;
  cursor: pointer;
}
.page-template-page-newpledge #primaryPostForm #pledge-choices-container ul li input[type=checkbox],
.single-pledge #primaryPostForm #pledge-choices-container ul li input[type=checkbox] {
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.page-template-page-newpledge #primaryPostForm #pledge-choices-container ul li input[type=checkbox] + label,
.single-pledge #primaryPostForm #pledge-choices-container ul li input[type=checkbox] + label {
  display: inline-block;
  padding-left: 30px;
  background-position: 0px 5px;
  line-height: 32px;
  cursor: pointer;
  background-image: url('img/pledge/checkbox-unchecked.png');
  background-size: 24px 22px;
  background-repeat: no-repeat;
}
.page-template-page-newpledge #primaryPostForm #pledge-choices-container ul li input[type=checkbox]:checked + label,
.single-pledge #primaryPostForm #pledge-choices-container ul li input[type=checkbox]:checked + label {
  background-image: url('img/pledge/checkbox-checked.png');
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio],
.single-pledge #primaryPostForm #image_color input[type=radio] {
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio] + label,
.single-pledge #primaryPostForm #image_color input[type=radio] + label {
  display: inline-block;
  float: left;
  margin: 3px 3px 3px 7px;
  width: 64px;
  height: 44px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 0;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]:first-child + label,
.single-pledge #primaryPostForm #image_color input[type=radio]:first-child + label {
  margin-left: 3px;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]#option_purple + label,
.single-pledge #primaryPostForm #image_color input[type=radio]#option_purple + label {
  background-color: #5b3d8a;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]#option_green + label,
.single-pledge #primaryPostForm #image_color input[type=radio]#option_green + label {
  background-color: #00879c;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]#option_blue + label,
.single-pledge #primaryPostForm #image_color input[type=radio]#option_blue + label {
  background-color: #175cb0;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]#option_red + label,
.single-pledge #primaryPostForm #image_color input[type=radio]#option_red + label {
  background-color: #952124;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]:checked + label,
.single-pledge #primaryPostForm #image_color input[type=radio]:checked + label {
  border: 3px solid #adadad;
  margin: 0 0 0 4px;
  border-radius: 3px;
}
.page-template-page-newpledge #primaryPostForm #image_color input[type=radio]:first-child:checked + label,
.single-pledge #primaryPostForm #image_color input[type=radio]:first-child:checked + label {
  margin-left: 0;
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio],
.single-pledge #primaryPostForm #background_image input[type=radio] {
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio] + label,
.single-pledge #primaryPostForm #background_image input[type=radio] + label {
  display: inline-block;
  float: left;
  margin: 3px 3px 6px 7px;
  width: 138px;
  height: 72px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 138px 72px;
  background-position: top left;
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]:nth-child(4n+1) + label,
.single-pledge #primaryPostForm #background_image input[type=radio]:nth-child(4n+1) + label {
  margin-left: 3px;
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]#option_grad_caps + label,
.single-pledge #primaryPostForm #background_image input[type=radio]#option_grad_caps + label {
  background-image: url('img/pledge/thumb-01-grad.jpg');
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]#option_football_team + label,
.single-pledge #primaryPostForm #background_image input[type=radio]#option_football_team + label {
  background-image: url('img/pledge/thumb-02-football.jpg');
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]#option_lockers + label,
.single-pledge #primaryPostForm #background_image input[type=radio]#option_lockers + label {
  background-image: url('img/pledge/thumb-03-lockers.jpg');
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]#option_girl_hallway + label,
.single-pledge #primaryPostForm #background_image input[type=radio]#option_girl_hallway + label {
  background-image: url('img/pledge/thumb-04-hallway.jpg');
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]#option_chain_fence + label,
.single-pledge #primaryPostForm #background_image input[type=radio]#option_chain_fence + label {
  background-image: url('img/pledge/thumb-05-fence.jpg');
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]#option_pattern + label,
.single-pledge #primaryPostForm #background_image input[type=radio]#option_pattern + label {
  background-image: url('img/pledge/thumb-06-diamond.jpg');
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]:checked + label,
.single-pledge #primaryPostForm #background_image input[type=radio]:checked + label {
  border: 3px solid #adadad;
  margin: 0 0 3px 4px;
  border-radius: 3px;
}
.page-template-page-newpledge #primaryPostForm #background_image input[type=radio]:nth-child(4n+1):checked + label,
.single-pledge #primaryPostForm #background_image input[type=radio]:nth-child(4n+1):checked + label {
  margin-left: 0;
}
.page-template-page-newpledge #primaryPostForm #email-signup-container input[type=checkbox],
.single-pledge #primaryPostForm #email-signup-container input[type=checkbox] {
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.page-template-page-newpledge #primaryPostForm #email-signup-container input[type=checkbox] + label,
.single-pledge #primaryPostForm #email-signup-container input[type=checkbox] + label {
  display: inline-block;
  padding-left: 30px;
  background-position: 0px 5px;
  line-height: 32px;
  cursor: pointer;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-size: 20px;
  margin-top: 1em;
  background-image: url('img/pledge/checkbox-unchecked.png');
  background-size: 24px 22px;
  background-repeat: no-repeat;
}
.page-template-page-newpledge #primaryPostForm #email-signup-container input[type=checkbox]:checked + label,
.single-pledge #primaryPostForm #email-signup-container input[type=checkbox]:checked + label {
  background-image: url('img/pledge/checkbox-checked.png');
}
.page-template-page-newpledge #primaryPostForm button[type=submit],
.single-pledge #primaryPostForm button[type=submit] {
  text-align: center;
  padding: 20px 15px;
  line-height: 1em;
  text-decoration: none;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  font-family: 'Podkova', serif;
  font-size: 23px;
  font-weight: 400;
  position: relative;
  z-index: 0;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 2em;
  letter-spacing: .75px;
  -webkit-font-smoothing: initial;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.page-template-page-newpledge #primaryPostForm button[type=submit]:before,
.single-pledge #primaryPostForm button[type=submit]:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
  background-color: #5b3d8a;
}
.page-template-page-newpledge #primaryPostForm button[type=submit]:hover,
.single-pledge #primaryPostForm button[type=submit]:hover {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.page-template-page-newpledge #primaryPostForm button[type=submit]:hover:before,
.single-pledge #primaryPostForm button[type=submit]:hover:before {
  filter: brightness(75%);
  -webkit-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -o-filter: brightness(75%);
  -ms-filter: brightness(75%);
}
.page-template-page-newpledge #primaryPostForm #image-render-container,
.single-pledge #primaryPostForm #image-render-container {
  width: 67%;
  margin-right: 1%;
}
.page-template-page-newpledge #primaryPostForm #image-render-container h2,
.single-pledge #primaryPostForm #image-render-container h2 {
  float: none;
}
.page-template-page-newpledge #primaryPostForm #image-render-container h4.mobile-only,
.single-pledge #primaryPostForm #image-render-container h4.mobile-only {
  display: none;
}
.page-template-page-newpledge #primaryPostForm #customize-image-container,
.single-pledge #primaryPostForm #customize-image-container {
  width: 32%;
}
.page-template-page-newpledge #primaryPostForm #customize-image-container h2.mobile-only,
.single-pledge #primaryPostForm #customize-image-container h2.mobile-only {
  display: none;
}
.page-template-page-newpledge #primaryPostForm #other_declaration_cont,
.single-pledge #primaryPostForm #other_declaration_cont {
  margin-top: .25em;
  display: none;
}
.single-pledge #pledge-top-container {
  background-color: #eee9e4;
  margin-bottom: 40px;
}
.single-pledge #pledge-top-container .mobile-only {
  display: none;
}
.single-pledge #pledge-top-container .desktop-only {
  display: block;
}
.single-pledge #pledge-top-container img {
  margin-bottom: 0;
}
.single-pledge #share-this-container {
  padding: 15px 20px 10px 40px;
}
.single-pledge #share-this-container p {
  font-family: 'Podkova', serif;
  font-size: 22px;
  margin: .25em;
}
.single-pledge #share-this-container ul {
  list-style: none;
  padding: .75em 0 1em 0;
}
.single-pledge #share-this-container ul li {
  display: inline-block;
  float: left;
  width: 47px;
  height: 47px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 47px 47px;
  font-size: 0;
  margin-right: 15px;
}
.single-pledge #share-this-container ul li.facebook {
  background-image: url('img/pledge/PP-icon-facebook-share@2x.png');
}
.single-pledge #share-this-container ul li.twitter {
  background-image: url('img/pledge/PP-icon-twitter-share@2x.png');
}
.single-pledge #share-this-container ul li.email {
  background-image: url('img/pledge/PP-icon-email-share@2x.png');
}
.single-pledge #share-this-container ul li a {
  display: block;
  width: 47px;
  height: 47px;
}
.single-pledge #share-this-container ul li:hover {
  filter: brightness(125%);
  -webkit-filter: brightness(125%);
  -moz-filter: brightness(125%);
  -o-filter: brightness(125%);
  -ms-filter: brightness(125%);
}
.single-pledge #pledge-to-container {
  margin-bottom: 40px;
}
.single-pledge #pledged-to-list ul {
  list-style: none;
  padding-left: 3em;
  margin-top: 1em;
}
.single-pledge #pledged-to-list ul li {
  font-family: 'Podkova', serif;
  font-size: 22px;
  margin-bottom: .25em;
}
.single-pledge #pledged-to-list ul li:before {
  font-family: 'fontawesome';
  content: '\f05d';
  color: #5b3d8a;
  font-size: 22px;
  margin-right: .5em;
}
.single-pledge #take-your-own {
  display: block;
  width: 100%;
  padding-top: 2em;
}
.single-pledge #take-your-own a {
  display: block;
  width: auto;
  text-align: center;
  padding: .5em .75em;
  text-decoration: none;
  color: #fff;
  font-family: 'Podkova', serif;
  font-size: 27px;
  font-weight: 100;
  position: relative;
  z-index: 0;
  margin-bottom: 5px;
  letter-spacing: .75px;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.single-pledge #take-your-own a:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
  background-color: #8f0005;
}
.single-pledge #take-your-own a:hover {
  text-decoration: none;
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.single-pledge #take-your-own a:hover:before {
  filter: brightness(75%);
  -webkit-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -o-filter: brightness(75%);
  -ms-filter: brightness(75%);
}
.single-pledge .pledge-links {
  margin: 2em 0 1em 0;
}
.single-pledge .pledge-links ul {
  list-style: none;
}
.single-pledge .pledge-links ul li {
  text-align: center;
}
.single-pledge .pledge-links ul li a {
  font-size: 20px;
  font-weight: 400;
}
.single-pledge .pledge-links ul li a:before {
  content: '\00BB';
  font-family: 'Podkova';
  font-weight: 700;
  font-size: 30px;
  margin-right: 16px;
}
#pledge-welcome-container {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.7);
  z-index: 2;
}
#pledge-welcome-container.show {
  display: block;
}
#pledge-welcome-container #pledge-welcome-message {
  display: block;
  position: relative;
  width: 90%;
  margin: 100px auto 40px auto;
  padding: 30px 30px 60px 30px;
  max-width: 768px;
  height: auto;
  background-color: #fff;
  background-image: url('img/bg/paper-light.jpg');
}
#pledge-welcome-container #pledge-welcome-message h2 {
  color: #006675;
  font-family: 'Podkova', serif;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 15px;
  clear: both;
  float: left;
  width: 100%;
  letter-spacing: 1px;
}
#pledge-welcome-container #pledge-welcome-message a.close-welcome {
  position: absolute;
  bottom: -20px;
  right: 30px;
  width: auto;
  text-align: center;
  padding: .5em .75em;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Podkova', serif;
  font-size: 27px;
  font-weight: 100;
  z-index: 0;
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
  letter-spacing: .75px;
}
#pledge-welcome-container #pledge-welcome-message a.close-welcome:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: skewX(10deg);
  -webkit-transform: skewX(10deg);
  -moz-transform: skewX(10deg);
  -o-transform: skewX(10deg);
  background-color: #8f0005;
}
#pledge-welcome-container #pledge-welcome-message a.close-welcome:hover {
  transform: translateX(3px);
  -webkit-transform: translateX(3px);
  transition: 200ms transform ease;
  -webkit-transition: 200ms transform ease;
  -moz-transition: 200ms transform ease;
  -o-transition: 200ms transform ease;
}
.stats-badges {
  display: none;
}
@media screen and (min-width: 798px) {
  .stats-badges {
    display: block;
    float: left;
    width: 30%;
    padding-left: 85px;
    box-sizing: border-box;
    text-align: center;
  }
  .stats-badges .stats-badge {
    margin-bottom: 35px;
  }
  .stats-badges .stats-badge img {
    width: 145px;
    display: block;
    margin: auto;
  }
  .stats-badges .stats-badge span {
    font-size: 15px;
    letter-spacing: .35px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 991px) {
  .page-template-page-newpledge .container,
  .single-pledge .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-template-page-newpledge .page-content {
    max-width: 768px;
  }
  .page-template-page-newpledge #primaryPostForm #image-render-container,
  .single-pledge #primaryPostForm #image-render-container {
    width: 100%;
    margin-right: 0;
  }
  .page-template-page-newpledge #primaryPostForm #image-render-container h2.desktop-only,
  .single-pledge #primaryPostForm #image-render-container h2.desktop-only {
    display: none;
  }
  .page-template-page-newpledge #primaryPostForm #image-render-container h4.mobile-only,
  .single-pledge #primaryPostForm #image-render-container h4.mobile-only {
    display: block;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container,
  .single-pledge #primaryPostForm #customize-image-container {
    width: 100%;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container h2.mobile-only,
  .single-pledge #primaryPostForm #customize-image-container h2.mobile-only {
    display: block;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image,
  .single-pledge #primaryPostForm #customize-image-container #background_image {
    max-width: 310px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-color-container,
  .single-pledge #primaryPostForm #customize-image-container #choose-color-container {
    width: 50%;
    display: inline-block;
    float: left;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-background-container,
  .single-pledge #primaryPostForm #customize-image-container #choose-background-container {
    width: 50%;
    display: inline-block;
    float: left;
  }
  .single-pledge #pledge-top-container,
  .single-pledge #pledge-to-container {
    background-color: transparent;
  }
  .single-pledge #pledge-top-container #share-this-container,
  .single-pledge #pledge-to-container #share-this-container {
    background-color: #eee9e4;
  }
  .single-pledge #pledge-top-container #pledge-profile-image,
  .single-pledge #pledge-to-container #pledge-profile-image {
    margin: 0 -20px;
    display: block;
  }
  .single-pledge #pledge-top-container .mobile-only,
  .single-pledge #pledge-to-container .mobile-only {
    display: block;
  }
  .single-pledge #pledge-top-container .mobile-only h1,
  .single-pledge #pledge-to-container .mobile-only h1 {
    padding-left: 45px;
    margin-top: 1em;
  }
  .single-pledge #pledge-top-container .desktop-only,
  .single-pledge #pledge-to-container .desktop-only {
    display: none;
  }
}
@media screen and (min-width: 520px) and (max-width: 991px) {
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-color-container {
    width: 100%;
    display: block;
    float: none;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-color-container h4 {
    display: inline-block;
    float: left;
    width: 37%;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-color-container #image_color {
    display: inline-block;
    float: left;
    width: 63%;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-background-container {
    width: 100%;
    display: block;
    float: none;
    clear: both;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-background-container h4 {
    display: inline-block;
    float: left;
    width: 37%;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-background-container #background_image {
    display: inline-block;
    float: left;
    width: 63%;
    max-width: 444px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(4n+1) + label {
    margin-left: 7px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(4n+1):checked + label {
    margin-left: 4px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(6n+1) + label {
    margin-left: 3px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(6n+1):checked + label {
    margin-left: 0;
  }
  #share-this-container .share-this-left,
  #share-this-container .share-this-right {
    display: inline-block;
    width: 50%;
    float: left;
  }
  #pledge_count_container,
  #take-your-own {
    max-width: 400px;
    display: block;
    float: none;
    margin: 0 auto;
  }
  #take-your-own {
    padding-top: 1em;
  }
}
@media screen and (min-width: 520px) and (max-width: 742px) {
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image {
    max-width: 310px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(6n+1) + label {
    margin-left: 7px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(6n+1):checked + label {
    margin-left: 4px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(4n+1) + label {
    margin-left: 3px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #background_image input[type=radio]:nth-child(4n+1):checked + label {
    margin-left: 0px;
  }
}
@media screen and (max-width: 797px) {
  .page-template-page-newpledge #primaryPostForm #customize-image-container h4,
  .single-pledge #primaryPostForm #customize-image-container h4 {
    margin-top: 10px;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-color-container,
  .single-pledge #primaryPostForm #customize-image-container #choose-color-container {
    width: 100%;
    display: block;
    float: none;
  }
  .page-template-page-newpledge #primaryPostForm #customize-image-container #choose-background-container,
  .single-pledge #primaryPostForm #customize-image-container #choose-background-container {
    width: 100%;
    display: block;
    float: none;
    padding-top: 15px;
  }
  .single-pledge #pledge-top-container #pledged-to-list ul,
  .single-pledge #pledge-to-container #pledged-to-list ul {
    padding-left: 15px;
  }
  .single-pledge #pledge-top-container .mobile-only h1,
  .single-pledge #pledge-to-container .mobile-only h1 {
    padding-left: 15px;
  }
}
@media screen and (max-width: 728px) {
  .page-template-page-newpledge #primaryPostForm #image-render-container,
  .single-pledge #primaryPostForm #image-render-container {
    width: auto;
    margin: 0 -20px;
  }
  .page-template-page-newpledge #primaryPostForm #image-render-container h4,
  .single-pledge #primaryPostForm #image-render-container h4 {
    padding-left: 20px;
  }
}
@media screen and (min-width: 992px) {
  .page-template-page-newpledge #primaryPostForm #customize-image-container,
  .single-pledge #primaryPostForm #customize-image-container {
    float: right;
  }
}
.offscreen-label {
  position: absolute;
  left: -999em;
  width: 1em;
  overflow: hidden;
}
footer {
  background-color: #460b0c;
  font-family: 'Oswald', sans-serif;
  font-weight: 100;
  color: #faf0e6;
  float: left;
  clear: both;
  width: 100%;
  padding: 33px 0;
}
footer:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
footer .footer-menu {
  display: none;
}
footer .social-menu {
  display: none;
}
footer .sponsor-callout {
  width: 180px;
  margin: auto;
}
footer .sponsor-callout a {
  color: #faf0e6;
}
footer .sponsor-callout p {
  text-align: right;
  display: inline-block;
  letter-spacing: .5px;
  margin-right: 20px;
  line-height: 25px;
  float: left;
  margin-top: 8px;
  font-size: 14px;
  text-decoration: none;
}
footer .sponsor-callout img {
  float: left;
}
footer #sitebyiii {
  display: block;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  position: relative;
  left: -1px;
}
footer #sitebyiii #iii-bug {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 3px;
  font-size: 0;
  background-image: url(img/iiibug.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  opacity: 0.9;
}
footer #sitebyiii.mobile-only {
  display: none;
}
footer #sitebyiii:hover #iii-bug {
  opacity: 1.0;
}
@media screen and (min-width: 870px) {
  footer .footer-menu {
    display: block;
    float: left;
    margin-top: 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  footer .footer-menu ul {
    display: block;
  }
  footer .footer-menu ul li {
    display: inline-block;
  }
  footer .footer-menu ul li:first-child {
    margin-left: 0;
  }
  footer .footer-menu ul li a {
    color: #faf0e6;
    text-decoration: none;
  }
  footer .footer-menu ul li a:hover {
    color: #fff;
  }
  footer .footer-menu a {
    color: #faf0e6;
  }
  footer .footer-menu .menu-footer-menu-espanol-container ul li {
    margin-left: 12px;
  }
  footer .footer-menu .menu-footer-menu-espanol-container ul li a {
    font-size: 15px;
  }
  footer .social-menu {
    display: block;
    float: left;
    margin-top: 0;
    margin-left: 25px;
    width: auto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  footer .social-menu a {
    color: #faf0e6;
    font-size: 0;
  }
  footer .social-menu a .fa {
    font-size: 16px;
  }
  footer .social-menu a:hover {
    color: #fff;
  }
  footer .sponsor-callout {
    display: block;
    width: auto;
    float: right;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}
@media screen and (max-width: 869px) {
  footer #sitebyiii.mobile-only {
    display: block;
    clear: both;
    padding-top: 30px;
    font-family: 'Oswald', sans-serif;
    font-weight: 100;
    color: #faf0e6;
  }
}
@media screen and (min-width: 1020px) {
  footer .footer-menu ul li {
    margin-left: 26px;
  }
  footer .menu-footer-menu-espanol-container ul li {
    margin-left: 12px;
  }
  footer .social-menu {
    margin-left: 45px;
  }
  footer .social-menu a {
    font-size: 0;
  }
  footer .social-menu a .fa {
    font-size: 20px;
  }
  footer .sponsor-callout p {
    margin-right: 10px;
  }
}
.current-menu-item > a {
  color: #ffffff !important;
}
